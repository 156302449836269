export class VehicleModelFeeDto {
    constructor(initializer) {
        this.referenceHour6 = initializer.referenceHour6;
        this.referenceHour12 = initializer.referenceHour12;
        this.referenceHour24 = initializer.referenceHour24;
        this.referenceExcess = initializer.referenceExcess;
        this.regularHour6 = initializer.regularHour6;
        this.regularHour12 = initializer.regularHour12;
        this.regularHour24 = initializer.regularHour24;
        this.regularExcess = initializer.regularExcess;
        this.weekendHour6 = initializer.weekendHour6;
        this.weekendHour12 = initializer.weekendHour12;
        this.weekendHour24 = initializer.weekendHour24;
        this.weekendExcess = initializer.weekendExcess;
        this.weekdayHour6 = initializer.weekdayHour6;
        this.weekdayHour12 = initializer.weekdayHour12;
        this.weekdayHour24 = initializer.weekdayHour24;
        this.weekdayExcess = initializer.weekdayExcess;
    }
}
