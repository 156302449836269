import { type ImageUploadResponseDto } from '@orcar/common';
import axiosClient from './axiosClient';

export const uploadImage = async ({
  formData,
}: {
  formData: FormData;
}): Promise<ImageUploadResponseDto> => {
  const response = await axiosClient.post(`/image/temp`, formData);

  return response.data;
};
