import { Box, Button, Dialog, Typography } from '@mui/material';
import { captureException, withScope } from '@sentry/electron';
import Lottie from 'lottie-react';
import { type FC, useEffect, useRef, useState } from 'react';
import { uploadImage } from '@/apis/image.api';
import AdminIcon from '@/assets/icons/admin.svg?react';
import scan_guide from '@/assets/images/scan_guide.png';
import ScanningJson from '@/assets/lottie/scanning.json';
import { GreyButton } from '@/components/StyledMuiComponent';
import { type ApprovalStatus } from '@/hooks/manager-approval.hook';
import DriverLicenseImageCensor from './DriverLicenseImageCensor';
import DriverLicenseImageManagerApproval from './DriverLicenseImageManagerApproval';

export type ScanResult = {
  type: number;
  direction: number;
  imageBuffer: Buffer;
};

export type OcrResult = {
  idNumber: string;
  licenseNumber: string;
  driverName: string;
  driverType: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
  onScanEnd: (scanResult: ScanResult, isOlderVersion: boolean) => void;
  approvalStatus: ApprovalStatus;
  startManagerApproval: () => Promise<void>;
  stopManagerApproval: () => void;
  reservationId: string;
};

const DriverLicenseScanDialog: FC<Props> = ({
  open,
  onClose,
  onScanEnd,
  approvalStatus,
  startManagerApproval,
  stopManagerApproval,
  reservationId,
}) => {
  const [askConfirm, setAskConfirm] = useState(false);
  const [isScanning, setIsScanning] = useState(false);
  const [scanResult, setScanResult] = useState<ScanResult | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const errorCount = useRef(0);

  // TODO: 이미지 원본 업로드 로직 제거 예정
  const uploadDriverLicenseImage = (scanResult: ScanResult) => {
    try {
      const formData = new FormData();

      const blob = new Blob([scanResult.imageBuffer], { type: 'image/jpeg' });
      formData.append('file', blob, `${reservationId}-scan-result.jpg`);

      uploadImage({ formData }).catch();
    } catch {}
  };

  const handleScan = async () => {
    setAskConfirm(false);
    setIsScanning(true);

    try {
      const result = await window.quantumScan.scan();

      uploadDriverLicenseImage(result);

      if (result.type !== 2 && result.type !== 3) {
        const blob = new Blob([result.imageBuffer], { type: 'image/jpeg' });
        const reader = new FileReader();
        reader.onload = function () {
          const arrayBuffer = this.result;

          withScope((scope) => {
            if (arrayBuffer instanceof ArrayBuffer) {
              const unit8Array = new Uint8Array(arrayBuffer);
              scope.addAttachment({
                data: unit8Array,
                filename: `${reservationId}-scan-result.jpg`,
                contentType: 'image/jpeg',
              });
            }
            captureException(
              new Error('quantumScan result.type is not 2 or 3'),
            );
          });
        };
        reader.readAsArrayBuffer(blob);
        throw new Error('Invalid type');
      }

      setScanResult(result);
    } catch (error) {
      if (++errorCount.current > 1) {
        setErrorMessage('스캔에 실패했습니다.\n관리자 확인을 받아주세요.');
      } else {
        setErrorMessage('제대로 인식되지 않았습니다.\n다시 시도해주세요.');
      }
    } finally {
      setIsScanning(false);
    }
  };

  useEffect(() => {
    if (open) {
      setAskConfirm(false);
      setIsScanning(false);
      setScanResult(null);
      setErrorMessage('');
      errorCount.current = 0;
    }
  }, [open]);

  const Content = scanResult ? (
    <DriverLicenseImageCensor
      scanResult={scanResult}
      onSelected={(isOlderVersion) => {
        onScanEnd(scanResult, isOlderVersion);
      }}
      onCanceled={() => {
        setScanResult(null);
      }}
    />
  ) : approvalStatus === 'WAITING' ? (
    <DriverLicenseImageManagerApproval onClosed={stopManagerApproval} />
  ) : (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          background: '#ffffff',
          padding: '40px',
        }}
      >
        <Typography
          sx={{ marginTop: '12px' }}
          fontSize={40}
          fontWeight={'bold'}
        >
          운전자 운전면허증 스캔
        </Typography>
        <Box sx={{ height: '16px' }} />
        <Box
          sx={{
            fontSize: 32,
            fontWeight: 'regular',
            color: 'text.secondary',
          }}
        >
          <Typography
            display='inline'
            fontSize={32}
            fontWeight='regular'
            color='text.secondary'
          >
            운전면허증{' '}
          </Typography>
          <Typography
            display='inline'
            fontSize={32}
            fontWeight='bold'
            color='primary.main'
          >
            앞면이 아래를 향하도록{' '}
          </Typography>
          <br />
          <Typography
            display='inline'
            fontSize={32}
            fontWeight='bold'
            color='primary.main'
          >
            스캐너 우측 상단
          </Typography>
          <Typography
            display='inline'
            fontSize={32}
            fontWeight='bold'
            color='text.secondary'
          >
            에 붙여서 올려주세요.
          </Typography>
        </Box>
        <Box sx={{ height: '40px' }} />
        <img width={760} height={480} src={scan_guide} alt='스캔 가이드' />
        <Box sx={{ height: '40px' }} />
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 20px 1fr',
            width: '100%',
          }}
        >
          <GreyButton
            sx={{
              width: '100%',
              fontSize: 40,
            }}
            onClick={() => {
              onClose();
            }}
          >
            <Typography fontSize={40} fontWeight='medium'>
              취소
            </Typography>
          </GreyButton>
          <Box height='20px'></Box>
          <Button
            variant='contained'
            sx={{
              height: '100%',
              fontSize: 40,
            }}
            onClick={() => {
              setAskConfirm(true);
            }}
          >
            <Typography fontSize={40} fontWeight='medium'>
              스캔하기
            </Typography>
          </Button>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          padding: '40px',
          background: theme.palette.grey[50],
        })}
      >
        <Typography
          sx={{
            fontSize: 32,
            fontWeight: 'bold',
            lineHeight: 1.5,
          }}
          color='#616161'
        >
          스캔할 운전면허증이 없는 경우,
        </Typography>
        <Typography
          fontSize={32}
          fontWeight='regular'
          color='#616161'
          lineHeight={1.5}
        >
          하단의 '관리자 확인' 버튼을 눌러 ‘운전경력증명서' 등
          <br />
          운전 자격을 증빙할 수 있는 서류를 직원에게 보여주세요.
          <br />
        </Typography>
        <Box sx={{ height: '40px' }} />
        <Button
          sx={{
            background: '#ffffff',
            width: '100%',
            paddingY: '28px',
          }}
          onClick={async () => {
            await startManagerApproval();
          }}
        >
          <AdminIcon width={44} height={44} />
          <Box sx={{ width: '8px' }} />
          <Typography fontSize={40} fontWeight='regular' color='text.secondary'>
            관리자 확인
          </Typography>
        </Button>
      </Box>
      {askConfirm && (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.2)',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={() => {
            setAskConfirm(false);
          }}
        >
          <Box
            sx={{
              border: '1px solid #d4d4d4',
              width: '720px',
              background: '#ffffff',
              margin: 'auto',
              padding: '40px',
            }}
          >
            <Typography
              sx={{ mt: 1, whiteSpace: 'pre-line', wordSpacing: '2px' }}
              fontSize='40px'
              fontWeight='medium'
              textAlign='center'
              lineHeight={1.4}
            >
              {`운전면허증이 \n스캐너 위에 잘 올려졌나요?`}
            </Typography>
            <Box
              sx={{
                mt: 2,
                mb: 2,
              }}
            >
              <Typography
                sx={{ display: 'inline' }}
                fontSize='32px'
                fontWeight='bold'
                textAlign='center'
                color='primary.main'
                lineHeight={1.4}
              >
                스캐너 우측 상단 모서리
              </Typography>
              <Typography
                sx={{ display: 'inline' }}
                fontSize='32px'
                fontWeight='medium'
                textAlign='center'
                color='text.secondary'
                lineHeight={1.4}
              >
                에 붙여서 올려주세요
              </Typography>
            </Box>
            <Box sx={{ height: '40px' }} />
            <Button
              variant='contained'
              sx={{
                height: '100%',
                width: '100%',
              }}
              onClick={() => {
                handleScan();
              }}
            >
              <Typography fontSize={40} fontWeight='medium'>
                스캔 시작
              </Typography>
            </Button>
          </Box>
        </Box>
      )}
      {isScanning && (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.2)',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              border: '1px solid #d4d4d4',
              width: '600px',
              height: '355px',
              background: '#ffffff',
              margin: 'auto',
              paddingTop: '52px',
              paddingBottom: '40px',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Lottie
                animationData={ScanningJson}
                loop={true}
                style={{ height: 140 }}
              />
            </Box>
            <Box sx={{ height: '40px' }} />
            <Typography fontSize={32} fontWeight='bold' textAlign='center'>
              운전면허증 스캔 중입니다.
            </Typography>
            <Box sx={{ height: '12px' }} />
            <Typography
              fontSize={28}
              fontWeight='normal'
              color='text.secondary'
              textAlign='center'
            >
              잠시만 기다려주세요.
            </Typography>
          </Box>
        </Box>
      )}
      {errorMessage && (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.2)',
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={() => {
            setErrorMessage('');
          }}
        >
          <Box
            sx={{
              border: '1px solid #d4d4d4',
              width: '720px',
              background: '#ffffff',
              margin: 'auto',
              padding: '40px',
            }}
          >
            <Box sx={{ height: '8px' }} />
            <Typography
              fontSize='36px'
              fontWeight='medium'
              textAlign='center'
              lineHeight={1.4}
              whiteSpace='pre-line'
            >
              {errorMessage}
            </Typography>
            <Box sx={{ height: '40px' }} />
            <Button
              variant='contained'
              sx={{
                height: '100%',
                width: '100%',
              }}
              onClick={() => {
                setErrorMessage('');
              }}
            >
              <Typography fontSize={40} fontWeight='medium'>
                닫기
              </Typography>
            </Button>
          </Box>
        </Box>
      )}
    </>
  );

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          position: 'relative',
          padding: 0,
        },
      }}
    >
      {Content}
    </Dialog>
  );
};

export default DriverLicenseScanDialog;
